<template>
  <div class="search-page">
    <div class="search-form">
      <h2>Search Pokes or Conversations</h2>
      <form @submit.prevent="performSearch(false)">
        <label for="requestType">Request Type:</label>
        <select v-model="search.request_type">
          <option value="p">Pokes</option>
          <option value="c">Conversations</option>
        </select>

        <label for="message">Message:</label>
        <input v-model="search.message" placeholder="Enter search message" />

        <label for="startTime">Start Time:</label>
        <input v-model="search.start_time" type="datetime-local" />

        <label for="endTime">End Time:</label>
        <input v-model="search.end_time" type="datetime-local" />

        <button type="submit" :disabled="isLoading">
          {{ isLoading ? 'Searching...' : 'Search' }}
        </button>
      </form>
    </div>

    <!-- Display Search Results -->
    <div v-if="searchResults.length > 0" class="search-results">
      <h3>Search Results ({{ totalCount }} found)</h3>
      <table>
        <thead>
          <tr>
            <th>Msg Type</th>
            <th>Username</th>
            <th>User Msg</th>
            <th>AI Generated Msg</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in searchResults" :key="result.id">
            <td>{{ result.request_type === 'P' ? 'Pokes' : 'Convos' }}</td>
            <td>{{ result.username }}</td>
            <td>{{ result.request }}</td>
            <td>{{ result.response }}</td>
            <td>{{ new Date(result.timestamp).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else-if="searchPerformed && searchResults.length === 0">
      <p>No results found for the given search criteria.</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: {
        message: '',
        request_type: 'p',  // Default to pokes
        start_time: '',
        end_time: '',
      },
      searchResults: [],      // Holds transactions data
      totalCount: 0,          // Holds total count of results
      searchPerformed: false, // Indicates if the search was performed
      isLoading: false,       // Tracks the loading state
      token: null,            // Authorization token
      autoRefreshInterval: null,
    };
  },
  methods: {
    getToken() {
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async performSearch(shouldWeAutoRefresh = false) {
      this.isLoading = true;  // Start loading state
      this.searchPerformed = true;
      this.searchResults = [];
      this.totalCount = 0;

      // Prepare the API URL with query parameters
      const url = new URL(`${process.env.VUE_APP_API_BASE_URL}/api/transactions/`);
      url.searchParams.append('message', this.search.message);
      url.searchParams.append('request_type', this.search.request_type);

      if (this.search.start_time) {
        url.searchParams.append('start_time', new Date(this.search.start_time).toISOString());
      }
      if (this.search.end_time) {
        url.searchParams.append('end_time', new Date(this.search.end_time).toISOString());
      }

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
        });
        if (response.ok) {
          const result = await response.json();
          // console.log('Parsed Result:', result);
          this.totalCount = result.count;

          // result.transactions.forEach((transaction, index) => {
          //   console.log(`Transaction ${index}:`, transaction);
          //   console.log(`Transaction ${index} response:`, transaction.response);
          // });
          this.searchResults = result.transactions.reverse();

          if (!shouldWeAutoRefresh) {
            this.startAutoRefresh();
          }
        } else {
          console.error('Error fetching search results');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isLoading = false; // End loading state
      }
    },
    startAutoRefresh() {
      // Clear any existing interval
      if (this.autoRefreshInterval) {
        clearInterval(this.autoRefreshInterval);
      }

      // Set the auto-refresh interval to 5 minutes (300,000 milliseconds)
      this.autoRefreshInterval = setInterval(() => {
        console.log('Auto-refreshing the search...');
        this.performSearch(true);  // Pass true to indicate that it's an auto-refresh
      }, 300000); // 5 minutes in milliseconds
    },
    stopAutoRefresh() {
      if (this.autoRefreshInterval) {
        clearInterval(this.autoRefreshInterval);
        this.autoRefreshInterval = null;
      }
    },
  },
  mounted() {
    this.getToken();
  },
  beforeUnmount() {
    this.stopAutoRefresh();
  },
};
</script>
<style scoped>
.search-form {
  margin: 20px;
}

.search-results table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.search-results th,
.search-results td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.search-results th {
  background-color: #000000;
}

.search-results td {
  word-wrap: break-word;
}

.search-results p {
  text-align: center;
  font-size: 1.2em;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
