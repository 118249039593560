<template>
    <div class="sampler-config-page">
      <h2>Sampler Configuration</h2>
  
      <!-- Tab Navigation for Sampler Config -->
      <div class="sub-tabs">
        <button @click="currentTab = 'list'" :class="{ active: currentTab === 'list' }">List Samplers</button>
        <button @click="currentTab = 'create'" :class="{ active: currentTab === 'create' }">Create Sampler</button>
      </div>
  
      <!-- Tab Content -->
      <div v-if="currentTab === 'list'">
        <!-- Existing Sampler List -->
        <div v-if="loading" class="loading-message">
          Loading samplers...
        </div>
  
        <div v-else-if="samplers.length > 0">
          <table>
            <thead>
              <tr>
                <th>Sampler Name</th>
                <th>Repetition Penalty</th>
                <th>Temperature</th>
                <th>Top_p</th>
                <th>Top_k</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sampler in samplers" :key="sampler.name">
                <td>{{ sampler.name }}</td>
  
                <!-- Repetition Penalty -->
                <td>
                  <input
                    type="number"
                    step="0.1"
                    v-model.number="sampler.repetition_penalty"
                    @change="markAsChanged(sampler)"
                  />
                </td>
  
                <!-- Temperature -->
                <td>
                  <input
                    type="number"
                    step="0.1"
                    v-model.number="sampler.temperature"
                    @change="markAsChanged(sampler)"
                  />
                </td>
  
                <!-- Top_p -->
                <td>
                  <input
                    type="number"
                    step="0.01"
                    v-model.number="sampler.top_p"
                    @change="markAsChanged(sampler)"
                  />
                </td>
  
                <!-- Top_k -->
                <td>
                  <input
                    type="number"
                    step="1"
                    v-model.number="sampler.top_k"
                    @change="markAsChanged(sampler)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
  
          <!-- Save Button -->
          <button class="save-button" @click="saveChanges">Save Changes</button>
        </div>
  
        <div v-else>
          <p>No samplers found.</p>
        </div>
      </div>
  
      <!-- Create New Sampler Tab -->
      <div v-if="currentTab === 'create'">
        <h3>Create New Sampler</h3>
  
        <form @submit.prevent="registerSamplerSettings">
          <div>
            <label for="alias">Alias:</label>
            <input v-model="newSampler.alias" id="alias" required />
          </div>
  
          <!-- Add the necessary fields for creating the new sampler -->
          <div>
            <label for="repetition_penalty">Repetition Penalty:</label>
            <input type="number" v-model.number="newSampler.repetition_penalty" id="repetition_penalty" step="0.1" />
          </div>
          <div>
            <label for="temperature">Temperature:</label>
            <input type="number" v-model.number="newSampler.temperature" id="temperature" step="0.1" />
          </div>
          <div>
            <label for="top_p">Top_p:</label>
            <input type="number" v-model.number="newSampler.top_p" id="top_p" step="0.01" />
          </div>
          <div>
            <label for="top_k">Top_k:</label>
            <input type="number" v-model.number="newSampler.top_k" id="top_k" step="1" />
          </div>
  
          <button type="submit" class="create-button">Create Sampler</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentTab: 'list', // Default to the list tab
        samplers: [],  // Store the samplers fetched from the API
        loading: true,  // Show loading while fetching samplers
        newSampler: {   // New sampler object for the form
          alias: '',
          repetition_penalty: 1.0,
          temperature: 1.0,
          top_p: 1.0,
          top_k: -1
        }
      };
    },
    methods: {
      async fetchSamplers() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/get_samplers`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch samplers');
          }
  
          const data = await response.json();
  
          this.samplers = data.map((sampler) => ({
            name: sampler.alias,
            repetition_penalty: sampler.repetition_penalty,
            temperature: sampler.temperature,
            top_p: sampler.top_p,
            top_k: sampler.top_k,
            changed: false,  // Track if the sampler has been changed
          }));
        } catch (error) {
          console.error('Error fetching samplers:', error);
        } finally {
          this.loading = false;
        }
      },
      markAsChanged(sampler) {
        sampler.changed = true; // Mark as changed
      },
      async saveChanges() {
        const changedSamplers = this.samplers.filter(sampler => sampler.changed);
  
        if (changedSamplers.length === 0) {
          window.alert("No changes to save.");
          return;
        }
  
        // Use the registerSamplerSettings API for saving changed samplers
        try {
          for (const sampler of changedSamplers) {
            const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/register_sampler_settings`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                alias: sampler.name,
                repetition_penalty: sampler.repetition_penalty,
                temperature: sampler.temperature,
                top_p: sampler.top_p,
                top_k: sampler.top_k
              }),
            });
  
            if (!response.ok) {
              throw new Error(`Failed to save sampler: ${sampler.name}`);
            }
          }
  
          window.alert("All changes saved successfully!");
  
          // After saving, reset the changed state and refresh the samplers
          this.fetchSamplers();
        } catch (error) {
          window.alert('Error saving changes: ' + error.message);
          console.error('Error saving changes:', error);
        }
      },
      async registerSamplerSettings() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/register_sampler_settings`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.newSampler),
          });
  
          if (!response.ok) {
            throw new Error('Failed to register sampler settings');
          }
  
          const data = await response.json();
          console.log('Sampler registered successfully:', data);
  
          // Show success message
          window.alert('Sampler created successfully!');
  
          // Clear form fields
          this.newSampler = {
            alias: '',
            repetition_penalty: 1.0,
            temperature: 1.0,
            top_p: 1.0,
            top_k: -1
          };
  
          // Switch to the 'list' tab and refresh the data
          this.currentTab = 'list';
          this.fetchSamplers();
  
        } catch (error) {
          window.alert('Error registering sampler: ' + error.message);
          console.error('Error registering sampler:', error);
        }
      }
    },
    mounted() {
      this.fetchSamplers();
    }
  };
  </script>
  
  <style scoped>
  .sampler-config-page {
    padding: 20px;
    margin-top: 20px; /* Add margin to create space from the main tab navigation */
  }
  
  .loading-message {
    text-align: center;
  }
  
  .sub-tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .sub-tabs button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    background-color: #616161;
  }
  
  .sub-tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead th, tbody td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  thead th {
    background-color: #000000;
  }
  
  .save-button, .create-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .save-button:hover, .create-button:hover {
    background-color: #0056b3;
  }
  </style>
  